import axios from "axios";
import React, { useState, useEffect, useRef ,  useCallback,   useContext,

} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Shimmerui from "./Shimmerui";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import AOS from "aos";
import Select from "react-select";
import $ from "jquery";
import "aos/dist/aos.css"; // You can also use <link> for styles
import InfiniteScroll from "react-infinite-scroller";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Content from "./Content";
import { FairPocketApi } from "../api/FairPockets_api";
import { BizMagnetApi } from "../api/BizMagnet_Api";
import { Helmet } from "react-helmet-async";
import { postApiFunction } from "../api/Post_api";
import { API_BASE_URL } from "../constants/Service";
import { base_url } from "../constants/Service";
import { toastProperties } from "../helper/toastifyProperties";
import VarifiedBadge from "./VarifiedBadge";
import { wishlistItemCount } from "../Contexts/WishListContext";
import VideoCard from "./VideoCard";
import FormModel from "./FormModel";
import WishListBtn from "./Buttons/WishListBtn";
import PrimaryBtn from "./Buttons/PrimaryBtn";

AOS.init();
const Product = (item, className ,initialCategoryState) => {
  const [selectedAreaId, setSelectedAreaId] = useState([]); // Initialize with an empty string
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [videoData, setVideoData] = useState([]);
  const [videoFilterData, setVideoFilterData] = useState([]);
  const { categoryId } = useParams();
  const pageTitle = `${categoryId.toUpperCase()}`;
  const canonicalUrl = `${base_url}/${categoryId}`;
  const isSmallScreen = window.innerWidth < 700;
  const [CityData, setCityData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState("4"); // Initialize with an empty string
  const [AreaData, setAreaData] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [minAmount, setMinAmount] = useState("500");
  const [maxAmount, setMaxAmount] = useState("500000");
  const [stepAmount, setStepAmount] = useState("5000");
  const [propsId, setPropsId] = useState(""); // Initialize with the value of props.selectedId
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [Results, setResults] = useState([]);
  const [howMany, setHowMany] = useState([]);
  const [value, setValue] = React.useState([]);
  const [name, setName] = useState([]);
  const [number, setNumber] = useState([]);
  const [nameOfCategories, setNameOfCategories] = useState([]);
  const [submitButtonColor, setSubmitButtonColor] = useState("#eb8500");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [brokerId, setBrokerId] = useState([]);
  const [defaultName, setDefaultName] = useState([]);
  const [defaultId, setDefaultId] = useState([]);
  const [otherContent, setOtherContent] = useState("");
  const [scrollBtn, setScrollBtn] = useState(false);
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const scrollToVideoSection = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const footerRef = useRef(null);
  const [perDay, setPerDay] = useState(false);
  const [status, setStatus] = useState(false);
  const [isPresent, setIsPresent] = useState(false);
    const [workPlace, setWorkPlace] = useState("");
    const [residentType, setResidentType] = useState(null);
      const [filterOptions, setFilterOptions] = useState({});
    const [setOfCategoriesToDisplay, setSetOfCategoriesToDisplay] = useState(
      []
    );      
        const [showFilterMenu, setShowFilterMenu] = useState(false);
    
          const [showSavingsPopUP, setShowSavingsPopUP] = useState(false);
         const [currentWidowSize, setCurrentWindowSize] = useState(
            window.innerWidth
          );


              const toggleFilterMenu = useCallback(() => {
                setShowFilterMenu((prevState) => !prevState);
              }, [setShowFilterMenu]);
    

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data, addDataToWishList, removeFromCart } =
  useContext(wishlistItemCount);
  
  const categoryRates = {
    PG: 1,
    "Zero B & S": 2,
    "1 RK": 3,
    "1 BHK": 4,
    "2 BHK": 5,
    "3 BHK": 6,
    "4 BHK": 7,
    "5 BHK & Above": 8,
    "Market Place": 9,
    "Short Stay": 10,
    "Adventure & Group Trips": 11,
    All: 12,
  };


  useEffect(() => {
    axios
      .post("https://bookmyqrcode.com/home_rental/api/api.php", {
        method: "getFilterData",
      })
      .then((res) => {
        console.log("filter data from new api", res);
        setFilterOptions(res.data.data);
      })
      .catch((err) => console.log("error in fetching fiter data", err));
  }, []);

  const pricesForRentalDetails = {
    //categoryID:Price
    [categoryRates["PG"]]: 50,
    [categoryRates["1 RK"]]: 100,
    [categoryRates["1 BHK"]]: 125,
    [categoryRates["2 BHK"]]: 200,
    [categoryRates["3 BHK"]]: 300,
    [categoryRates["4 BHK"]]: 400,
    [categoryRates["Short Stay"]]: 25,
  };

    const addItemToWishList = useCallback(() => {
      addDataToWishList(item);
      setIsPresent(true);
    }, [item, addDataToWishList, setIsPresent]);


       const addCategoriesToBeDisplayed = useCallback((p_category) => {
          console.log("p_category", p_category)
          setSetOfCategoriesToDisplay((prevState) => {
            const categoryAlreadyExist = prevState.find(
              (category) => category.categoryId == p_category.categoryId
            );
            if (!categoryAlreadyExist) {
              return [...prevState, p_category];
            }
            return prevState;
          });
        }, []);

        useEffect(() => {
          if (!Array.isArray(selectedAreaId)) return; // Prevent errors
        
          setSetOfCategoriesToDisplay([]);
        
          selectedAreaId.forEach((areaId) => {
            filterOptions[selectedCityId]?.areas
              .find((area) => area.areaId === areaId)
              ?.categories.forEach((category) =>
                addCategoriesToBeDisplayed(category)
              );
          });
        
          handleSelectChange(initialCategoryState);
        }, [selectedAreaId, initialCategoryState]);
        

  const fetchData = async (catID, offset = 0) => {
    try {
      let id =
        catID === undefined || catID === "12" || categoryId === undefined
          ? ""
          : catID;
      if (catID === "12") {
        id = "";
      }
      const data = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getDataInventory",
          categoryId: `${id}`,
          limit: "30",
          offset: `${offset}`,
        }
      );
      // console.log("datafetchvideos", data);
      if (data.data.status === "Success") {
        setVideoFilterData((prevData) => [...prevData, ...data?.data?.data]);
        setHowMany(data.data.inventroy_count);
        // console.log("totalinventory", data.data.inventroy_count);
        setVideoData(data.data.data);
        setStatus(true);
        // console.log("Fetch Data Api Calling.....", catID);
      } else {
        setVideoFilterData([]);
        setHowMany(0);
        setVideoData([]);
        setStatus(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


    const removeFromWishList = useCallback(() => {
      removeFromCart(item);
      setIsPresent(false);
    }, [item, addDataToWishList, setIsPresent]);

  const loadMore = async (offset) => {
    try {
      if (videoFilterData.length >= howMany) {
        // console.log("All data has been loaded");
        setHasMore(false);
        return; // No more data to load
      }
      const newOffset = offset + 30;
      await fetchData(defaultId, newOffset); // Assuming defid is the category ID
    } catch (error) {
      console.error("Error loading more data:", error);
    }
  };

  const shareContent = () => {
    const message = `${window.location.href}`;

    window.open(`https://wa.me/?text=${encodeURIComponent(message)}`);
  };

  // Function to handle scroll to footer
  const scrollToFooter = () => {
    if (scrollBtn == false) {
      footerRef.current.scrollIntoView({ behavior: "smooth" });
      setScrollBtn(true);
    } else {
      window.scrollTo(0, 0);
      setScrollBtn(false);
    }
  };

  useEffect(() => {
    if (defaultId.length !== 0) {
      Sliders();
    }
  }, [defaultId]);

  const Sliders = async () => {
    try {
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getData",
          table: "sliders",
          column: "CategoryId",
          value: `${defaultId}`,
        }
      );

      console.log("dataSlider", response);

      // Filter sliders with slider_type "home"
      if (response.data.status !== "false") {
        setProduct(response.data.data);
        setOtherContent(response.data.other_content);
      } else {
        setProduct([]);
        setOtherContent([]);
      }
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };

  // ("categoryId", categoryId.replace(/-/g, " "));

  useEffect(() => {
    fetchDataCity();
    fetchDataArea();
  }, []);

  useEffect(() => {
    fetchcategorys();
  }, [categoryId]);

  const fetchcategorys = async () => {
    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getData",
        table: "categories",
        column: "status",
        value: "1",
        orderColumn: "sort_order",
        ordervalue: "Desc",
      }
    );
    const filteredData = data?.data?.data;

    // Remove the first element from the filtered data

    setCategory(filteredData);
    // console.log("categoryId", categoryId.toUpperCase());

    const categoryItem = filteredData.find(
      (item) => item.name.toLowerCase() === categoryId.replace(/-/g, " ")
    );
    const categoryIds = categoryItem ? categoryItem.categoryId : null;

    if (categoryIds != null) {
      setDefaultId(categoryIds);
      // console.log("categoryItem", categoryItem);

      if (categoryId) {
        // Assuming your URL format is consistent with "nameId" (e.g., "categoryName123")
        const categoryName = categoryId.replace(/-/g, " ");

        setDefaultName(categoryName);

        // Log the category name and ID
        // console.log("Category Name:", categoryName);

        // if(categoryId !== "5-bhk--&-above"){
        //   const categoryItem = filteredData.find(item => item.name.toLowerCase() === categoryId.replace( '-' ," "));
        //   const categoryIds = categoryItem ? categoryItem.categoryId : null;
        //   console.log("categoryItem", categoryItem);
        //   setdefaululid(categoryIds)
        // }
        // else{
        //   categoryId.replace( '5-bhk--&-above' ," 5 bhk & above")
        //   setdefaululid("8")
        // }
      }
    } else {
      navigation("/");
    }
  };

  useEffect(() => {
    if (defaultId.length !== 0) {
      fetchData(defaultId);
    }
  }, [defaultId]);

  const [originalButtonColor, setOriginalButtonColor] = useState("#eb8500");
  useEffect(() => {
    // Set the original button color when the component mounts
    setOriginalButtonColor(""); // Set your original color here
  }, []);

  // console.log("NameOfCategoerys", nameOfCategories);

  // console.log("Results", Results);

  // console.log("videoFilterDatssa", videoFilterData);

  // console.log("setSelectedCategoryName", selectedCategoryName);

  // console.log("propsid", propsId);

  // console.log("maxAmount", maxAmount);

  const handleCityChange = (selectedOption) => {
    setSelectedCityId(selectedOption.value);
  };

  const handleAreaChange = (selectedOptions) => {
    setSelectedAreaId(selectedOptions.map((option) => option.value));
    // console.log(
    //   "areas",
    //   selectedOptions.map((option) => option.value)
    // );
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setCategoryIds(selectedOption);
      setSelectedCategoryName(selectedOption.label);
    }
  };

  const handleFilter = async () => {
    try {
      // Set propsid to an empty string

      setLoading(true);

      // console.log("111", categoryIds);

      setPropsId("");
      if (selectedCategoryName === "") {
        setNameOfCategories(selectedCategoryName);
      } else {
        setNameOfCategories(selectedCategoryName); // Use selectedCategoryName directly
      }

      // Create the request object
      const request = {
        method: "getDataInventory",
        categoryId: categoryIds === "12" ? "" : `${categoryIds}`,
        areaId: `${selectedAreaId}`,
        rent: `${value[0]},${value[1]}`,
        cityId: `${selectedCityId}`,
      };
      // console.log("handleFilter", request);
      // Make the API call
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        request
      );
      // console.log("selectedCategoryName", selectedCategoryName);

      // Always update the category name, regardless of the API response

      // Check the response status

      if (response.data.status === "Success") {
        setVideoFilterData(response.data.data);
        console.log("response",response)
        setHowMany(response.data.data.length);
        setLoading(false);
        scrollToVideoSection.current.scrollIntoView({ behavior: "smooth" });
      } else if (response.data.status === "false") {
        // Handle the case when no data is found
        setHowMany("No");
        setLoading(false);
        setVideoFilterData([]);
        toast.error("No Data Found", {
          position: "bottom-right",
          autoClose: 4988,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error--->", error);
      // Handle the error, e.g., show an error message.
    }
  };

  const fetchDataCity = async () => {
    try {
      const result = await postApiFunction(
        "getData",
        "city",
        "status",
        "1",
        null,
        null,
        API_BASE_URL
      );
      if (result.status === "Success") {
        setCityData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataArea = async () => {
    try {
      const result = await postApiFunction(
        "getData",
        "area",
        "cityId,status",
        `${selectedCityId},1`,
        "sort_order",
        "asc",
        null,
        API_BASE_URL
      );
      if (result.status === "Success") {
        setAreaData(result.data);
      } else {
        setAreaData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataArea();
  }, [selectedCityId]);

  useEffect(() => {
    if (categoryIds.length > 0) {
      const selectedCategoriesData = category.filter((cat) =>
        categoryIds.includes(cat.categoryId)
      );

      if (selectedCategoriesData.length > 0) {
        const allMaxValues = selectedCategoriesData.map((cat) =>
          parseFloat(cat.max_amount)
        );
        const allMinValues = selectedCategoriesData.map((cat) =>
          parseFloat(cat.min_amount)
        );

        const maxAmount = Math.max(...allMaxValues);
        const minAmount = Math.min(...allMinValues);

        // Set default values if minAmount is null or empty
        const finalMinAmount = minAmount || 5000;

        // Set default values if maxAmount is null or empty
        const finalMaxAmount = maxAmount || 500000;

        setValue([finalMinAmount, finalMaxAmount]);
        setMaxAmount(finalMaxAmount);
        setMinAmount(finalMinAmount);

        // console.log("selectedCategoriesData", selectedCategoriesData);
        // console.log("allMinValues", allMinValues);
        // console.log("allMaxValues", allMaxValues);
        // console.log("minAmount", finalMinAmount);
        // console.log("maxAmount", finalMaxAmount);
        // console.log("setValue", categoryIds);
      } else {
        // Handle the case when no categories are selected
        setDefaultValues();
      }
    } else {
      setDefaultValues();
    }
  }, [categoryIds, category]);

  const setDefaultValues = () => {
    console.log("No categories selected");
  };

  // console.log("selectadedatabr", brokerId);

  useEffect(() => {
    // Set the default value to [1] if categoryIds is an empty array
    if (categoryIds.length === 0) {
      setCategoryIds(defaultId);

      // console.log("125", defaultId);
    }
  }, [defaultId]);

  const SendData = async () => {
    if (loader) {
      return;
    }

    const request = {
      method: "setData",
      table: "enquiry",
      data: {
        name: `${name}`,
        brokerId: `${selectedData.brokerId}`,
        number: `${number}`,
        shifting_date: `${selectedDate}`,
        inventroyId: `${selectedData.inventroyId}`,
      },
    };
    // console.log("requestSendDatass", request);

    if (name.length === 0) {
      toast.error("Enter Name", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }
    if (number.length < 1) {
      toast.error("Enter Number", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    } else if (number.length !== 10) {
      toast.error("Enter 10 Digit", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    } else if (
      !(selectedDate instanceof Date) ||
      isNaN(selectedDate.getTime())
    ) {
      toast.error("Enter Date please", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    setLoader(true);
    const formSubmitData = {
      name: name,
      brokerId: selectedData.brokerId,
      number: number,
      shifting_date: selectedDate,
      inventroyId: selectedData.inventroyId,
    };

    const FairPocketApiData = {
      name: name,
      mobile: number,
      email: "", // You may leave it as an empty string if not provided
      project: selectedData.category_name,
      source: "4",
      requirement: `<br><b>Inventory Id:</b> ${selectedData.inventroyId},<br>
            <b>Title:</b> ${selectedData.title},<br>
            <b>Broker Name:</b> ${selectedData.broker_name},<br>
            <b>City Name:</b> ${selectedData.city_name},<br>
            <b>Rent:</b> ${selectedData.rent},<br>
            <b>Furniture Type:</b> ${selectedData.furniture_type},<br>
            <b>Amenities:</b> ${selectedData.admin_remark}`,
    };

    const bizMagnetDataClient = {
      name: name,
      number: number,
      text: selectedData.broker_number,
      title: selectedData.title,
      data_to_send: "client",
    };

    const bizMagnetDataBroker = {
      name: selectedData.broker_name,
      number: selectedData.broker_number,
      text: number,
      title: selectedData.title,
      data_to_send: "broker",
    };

    try {
      const formDataSubmitApi = await postApiFunction(
        "setData",
        "enquiry",
        null,
        null,
        null,
        null,
        formSubmitData
      );

      await FairPocketApi(FairPocketApiData);

      await BizMagnetApi(bizMagnetDataClient);

      await BizMagnetApi(bizMagnetDataBroker);

      if (formDataSubmitApi?.status === "Success") {
        setSubmitButtonColor("#006622");
        setFormSubmitted(true);
        toast.success("Success", toastProperties);
        setName("");
        setNumber("");
        setSelectedDate(new Date());
        setTimeout(() => {
          setSubmitButtonColor("#eb8500");
          setFormSubmitted(false);
        }, 4000);
      } else {
        alert(false);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    // console.log("currentUrl", currentUrl);
    if (currentUrl.includes("id")) {
      setModalOpen(true);
      // console.log("yes it has id in url ");

      const idParam = new URLSearchParams(window.location.search).get("id");

      const idNumber = idParam ? parseInt(idParam.replace(/\D/g, "")) : null;

      if (!isNaN(idNumber)) {
        console.log("Number from URL:", idNumber);
      } else {
        console.log("No valid number found in URL");
      }

      const fetchVideoById = async () => {
        try {
          const data = await axios.post(
            "https://bookmyqrcode.com/home_rental/api/api.php",
            {
              method: "getDataInventory",

              column: "inventroyId",
              value: `${idNumber}`,
            }
          );
          // console.log("databyid", data.data.data[0]);

          setSelectedData(data.data.data[0]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchVideoById();
    }
  }, [window.location.href]);
  const openModal = (data) => {
    setSelectedData(data);
    setModalOpen(true);

    const newcurrentUrl = new URL(window.location.href);
    newcurrentUrl.searchParams.set("id", data.inventroyId);
    window.history.pushState(null, "", newcurrentUrl.href);
  };

  const handleModalCloseByButton = () => {
    window.history.back();
    setModalOpen(false);
  };

  window.onpopstate = () => {
    window.onpopstate = () => {};
    setModalOpen(false);
  };

  React.useEffect(() => {
    document.body.addEventListener("click", () => {
      setModalOpen(false);
    });
  });

     const toggleSavingsPopUp = useCallback(() => {
        setShowSavingsPopUP((prevState) => !prevState);
      }, [setShowSavingsPopUP]);

  if (!status) {
    return <Shimmerui />;
  }
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      {/* <div>
        <a
          href="https://wa.me/919990025112"
          target="_blank"
          className="fixed-button-whatsapp"
        >
          <img
            src="Assets/image/whatsapp.png"
            alt=""
            style={{ height: "35px" }}
          />
        </a>
        {!scrollBtn && (
          <button className="fixed-button z-1" onClick={scrollToFooter}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-down-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
            </svg>
          </button>
        )}
        {scrollBtn && (
          <button
            className="fixed-button secondfixed-button z-1"
            onClick={scrollToFooter}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-up-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
            </svg>
          </button>
        )}
      </div> */}
      <div className="text-white ">
        <div id="carouselExample" className="carousel slide">
          <div className="carousel-inner">
            {product.map((item, index) => (
              <div
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
                style={{ position: "relative", height: "350px" }}
              >
                <img
                  className="sliderimg"
                  src={"https://bookmyqrcode.com/home_rental/" + item.image}
                  alt={`Slide ${index}`}
                  style={{
                    height: "350px",
                    objectFit: "cover",
                    width: "100%",
                    position: "relative",
                  }}
                />
                <div
                  className="dark-overlay"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity as needed
                  }}
                ></div>
                <div
                  className="col-md-8  col-12 text-center  position-absolute start-50 translate-middle"
                  style={{ top: "50%" }}
                >
                  <h2 style={{ fontWeight: "650" }}>{item.category_name}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.category_desc }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="moileornot">
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
      <div className="container d-flex justify-content-center mt-3">
      {modalOpen && (
        <FormModel
          selectadedata={selectedData}
          handleModalCloseByButton={handleModalCloseByButton}
          PerDay={perDay}
          videoFilterData={videoFilterData}
          openModal={openModal}
          setName={setName}
          name={name}
          number={number}
          setNumber={setNumber}
          SendData={SendData}
          workPlace={workPlace}
          setWorkPlace={setWorkPlace}
          residentType={residentType}
          setResidentType={setResidentType}
        />
      )}
        <div className="row videosform section col-md-12">
          <div className="col-md-3 my-5">
          <div
          className="d-flex flex-column gap-3 pt-2 pt-md-0 mx-auto"
          style={{ height: "fit-content", zIndex: 10, maxWidth: "90vw", width: "100%" }}
        >
          {/* <div
            className="d-md-none d-flex gap-3 align-items-center px-4 py-3"
            style={{ boxShadow: "0 3px 13.5px 0 rgba(0, 0, 0, 0.15)" }}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#E7A03C"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-sliders-horizontal"
              >
                <line x1="21" x2="14" y1="4" y2="4" />
                <line x1="10" x2="3" y1="4" y2="4" />
                <line x1="21" x2="12" y1="12" y2="12" />
                <line x1="8" x2="3" y1="12" y2="12" />
                <line x1="21" x2="16" y1="20" y2="20" />
                <line x1="12" x2="3" y1="20" y2="20" />
                <line x1="14" x2="14" y1="2" y2="6" />
                <line x1="8" x2="8" y1="10" y2="14" />
                <line x1="16" x2="16" y1="18" y2="22" />
              </svg>
            </div>
            <div className="fw-semibold" style={{ fontSize: 20.58 }}>
              Filter
            </div>
          </div> */}
          <div
            className="fw-semibold"
            style={{ fontSize: "clamp(24px, 8vw, 30px)" }}
          >
            Filter
          </div>
          <div
            className="p-4 fw-semibold d-flex flex-column gap-3 mx-auto bg-white start-50 filterFormMenu"
            style={{
              border: "1px solid rgba(37, 37, 37, 0.32)",
              borderRadius: 14,
              height: "fit-content",
              fontSize: 20,
              top: "120%",
            }}
          >
            <div>Property Types</div>
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: 18 }}
            >
              <div>City</div>
              <div className="text-danger">Gurgaon</div>
            </div>
            <div className="d-flex gap-1 flex-column fs-6 fw-normal">
              <div className="fw-normal" style={{ fontSize: 18 }}>
                Area
              </div>
              <Select
                className=""
                isMulti
                placeholder="Select Area"
                noOptionsMessage={() => "Please select any City to see Areas."}
                options={filterOptions[selectedCityId]?.areas.map(
                  (areadata) => ({
                    label: areadata.name,
                    value: areadata.areaId,
                  })
                )}
                onChange={(selectedOptions) =>
                  handleAreaChange(selectedOptions)
                }
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor:
                      state.isFocused || state.menuIsOpen ? "#E7A03C" : "grey",
                    boxShadow:
                      state.isFocused || state.menuIsOpen
                        ? `0 0 0 1px #E7A03C`
                        : baseStyles.boxShadow,
                    borderRadius: 8,
                    "&:hover": { borderColor: "#E7A03C" },
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#FFDDAE",
                    primary: "#E7A03C",
                  },
                })}
              />
            </div>
            <div className="d-flex gap-1 flex-column fs-6 fw-normal">
              <div className="fw-normal" style={{ fontSize: 18 }}>
                Product
              </div>
              <Select
                className=""
                id=""
                isMulti={false} // Set to false for single selection
                options={setOfCategoriesToDisplay.map((category) => ({
                  label: category.categoryName,
                  value: category.categoryId,
                }))}
                noOptionsMessage={() =>
                  "Please select any Area to see categories."
                }
                onChange={handleSelectChange}
                value={{
                  label: categoryIds.label,
                  value: categoryIds.value,
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor:
                      state.isFocused || state.menuIsOpen ? "#E7A03C" : "grey",
                    boxShadow:
                      state.isFocused || state.menuIsOpen
                        ? `0 0 0 1px #E7A03C`
                        : baseStyles.boxShadow,
                    borderRadius: 8,
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    fontSize: 16,
                    color: "gray"
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#FFDDAE",
                    primary: "#E7A03C",
                  },
                })}
              />
            </div>
            {categoryIds.label != "Select Category" && (
              <div className="d-flex gap-1 flex-column fs-6 fw-normal">
                <div className="fw-normal" style={{ fontSize: 18 }}>
                  Price Range
                </div>
                <div>
                  <Box className="container">
                    <Slider
                      sx={{
                        color: "white",
                        height: 6,
                        "& .MuiSlider-thumb": {
                          backgroundColor: "#E7A03C",
                        },
                        "& .MuiSlider-rail": {
                          backgroundColor: "grey",
                        },
                        "& .MuiSlider-track": {
                          backgroundColor: "#E7A03C",
                          borderColor: "#E7A03C",
                        },
                      }}
                      getAriaLabel={() => "Temperature range"}
                      defaultValue={30}
                      valueLabelDisplay="auto"
                      value={value}
                      Slider
                      onChange={handleChange}
                      step={stepAmount}
                      marks
                      min={parseFloat(minAmount)}
                      max={parseFloat(maxAmount)}
                    />
                  </Box>
                  <div className="d-flex gap-3">
                    <div
                      className="p-2 rounded w-50"
                      style={{ border: "1px solid rgba(37, 37, 37, 0.32)" }}
                    >
                      ₹ {value[0]}
                    </div>
                    <div
                      className="p-2 rounded w-50"
                      style={{ border: "1px solid rgba(37, 37, 37, 0.32)" }}
                    >
                      ₹ {value[1]}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <PrimaryBtn
              className="w-100"
              onClick={() => {
                handleFilter();
                if (categoryIds) {
                  toggleSavingsPopUp();
                }
                if (currentWidowSize > 768) {
                  toggleFilterMenu();
                }
              }}
            >
              Search
            </PrimaryBtn>
          </div>
        </div>
          </div>

          <div
            className="col-md-9 "
            id="mobilediv"
            style={{ position: "relative" }}
            ref={scrollToVideoSection}
          >
            {loading && (
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="shimmer-container">
                    <iframe src="https://lottie.host/embed/d9f11e0b-3586-4c27-b585-686572b5343a/CSxWt1J3W5.json"></iframe>
                    <div className="shimmer"></div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-8 d-flex align-items-center">
                <h3 className="text-start Heading mb-0">
                  {howMany} Results | For {nameOfCategories} Rent
                </h3>
              </div>
              <div className="col-4">
                {" "}
                {/* <div className="mb-3">
                <div className="input-group mb-3">
                  <select className="form-select" id="inputGroupSelect01">
                    <option value="" disabled selected>
                      Sort by : Relevance
                    </option>

                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div> */}
              </div>
            </div>
            <div className="card-grid">
                  {videoFilterData.map((item, index) => (
                    <VideoCard openModal={openModal} item={item} key={index} />
                  ))}
                </div>
            {/* <div className="d-flex col-12 videobar justify-content-start p-2 py-1">
                <div className="row">
                  <div className="d-flex col-12 videobar container-fluid">
                    <div className="row py-0 mb-0  mt-0">
               {
                videoFilterData.map((item) =>(
                  <div
                  className={`overflow-hidden p-3 d-flex flex-column gap-3 rounded-4 shadow bg-light col-3 ${className}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    openModal(item);
                  }}
                >
                  <div className="video position-relative rounded-3 overflow-hidden">
                  
                    <VarifiedBadge areaDeatails={item.area_name} />
                    <img
                      src="Assets/image/playbutton2.svg"
                      alt=""
                      className="position-absolute top-50 start-50"
                      style={{ transform: "translate(-50%, -50%)" }}
                      width={50}
                    />
                    <img
                      src={
                        item.image !== ""
                          ? "https://bookmyqrcode.com/home_rental/" + item.image
                          : "Assets/image/Group 168.png"
                      }
                      alt=""
                      className="rounded"
                      style={{ width: "100%", objectFit: "cover", height: 400 }}
                    />
                  </div>
                  <div className="d-flex gap-3">
                    <div
                      className="p-2 rounded w-50 text-center"
                      style={{
                        border: "1px solid rgba(37, 37, 37, 0.32)",
                        borderRadius: 9.21,
                        fontSize: 14,
                      }}
                    >
                      <div className="fw-semibold">Rent</div>
                      <div>
                        <span className="fw-semibold">{item.rent}</span>
                        {item.categoryId === "10" ? "/Daily" : " /Month"}
                      </div>
                    </div>
                    <div
                      className="p-2 w-50 text-center"
                      style={{
                        border: "1px solid rgba(37, 37, 37, 0.32)",
                        borderRadius: 9.21,
                        fontSize: 14,
                      }}
                    >
                      <div className="fw-semibold">Area</div>
                      <div className="single-line-clamp ">{item.area_name}</div>
                    </div>
                  </div>
            
                  <button
                    className="btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      isPresent ? removeFromWishList() : addItemToWishList();
                    }}
                    style={{
                      backgroundColor: !isPresent ? "#E7A03C" : "transparent",
                      border: "2px solid #E7A03C",
                      color: isPresent ? "#E7A03C" : "white",
                    }}
                  >
                    {isPresent
                      ? "Remove from Cart"
                      : `Get Owner Number @${
                          pricesForRentalDetails[parseInt(item?.categoryId, 10)] ?? "N/A"
                        }/-`}{" "}
                  </button>
                  <button
                    className="btn text-dark"
                    style={{
                      backgroundColor: "#E0E0E0",
                    }}
                  
                  >
                
                    View Property
                  </button>
                </div>
                ))
               }
               </div>
               </div>
               </div>
               </div> */}
            <div className="buttonss d-flex justify-content-center my-4 ">
              <button hidden={videoFilterData.length >= 0 ? true : false}>
                Load More
              </button>
            </div>
          </div>
        </div>
      </div>            
      <div
            className="position-fixed"
            style={{ zIndex: 1010, bottom: "1rem", right: 8 }}
          >
          <WishListBtn count={data.size} />
          </div>
      <div ref={footerRef}>
        <Content otherContent={otherContent} />
        <Footer />
      </div>
    </div>
  );
};

export default Product;

      
