import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Shimmer = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className=" col-md-12 slider  section" id="category">
      <div className="container">
        <div>
        <div>
            <h4 className="Heading"></h4>
          </div>
          <Slider className="ourclientsvs" {...settings}>
            <div className="clientimg">
              <div className="categoryShimmerUi ">
                <h4></h4>
              </div>
              <div className="">
                <h4 className="shimmeuitext">.</h4>
              </div>
            </div>
            <div className="clientimg">
              <div className="categoryShimmerUi ">
                <h4></h4>
              </div>
              <div className="">
                <h4 className="shimmeuitext">.</h4>
              </div>
            </div>
            <div className="clientimg">
              <div className="categoryShimmerUi ">
                <h4></h4>
              </div>
              <div className="">
                <h4 className="shimmeuitext">.</h4>
              </div>
            </div>
            <div className="clientimg">
              <div className="categoryShimmerUi ">
                <h4></h4>
              </div>
              <div className="">
                <h4 className="shimmeuitext">.</h4>
              </div>
            </div>
            <div className="clientimg">
              <div className="categoryShimmerUi ">
                <h4></h4>
              </div>
              <div className="">
                <h4 className="shimmeuitext">.</h4>
              </div>
            </div>
            <div className="clientimg">
              <div className="categoryShimmerUi ">
                <h4></h4>
              </div>
              <div className="">
                <h4 className="shimmeuitext">.</h4>
              </div>
            </div>
            <div className="clientimg">
              <div className="categoryShimmerUi ">
                <h4></h4>
              </div>
              <div className="">
                <h4 className="shimmeuitext">.</h4>
              </div>
            </div>
            <div className="clientimg">
              <div className="categoryShimmerUi ">
                <h4></h4>
              </div>
              <div className="">
                <h4 className="shimmeuitext">.</h4>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Shimmer;
